import React from 'react';
import { AppProps } from 'next/app';
import { GraphQLClient, GraphQLProvider } from 'graphql-clientgen';
import { API_URL } from '../../config/apiURL';
import { AppMethods, AppMethodsInfo } from '../../client/client';
import Head from 'next/head';

const MyApp: React.FC<AppProps> = function MyApp({ Component, pageProps }) {
  const client = React.useMemo(() => {
    return new GraphQLClient({
      url: API_URL,
      methods: AppMethods,
      methodsInfo: AppMethodsInfo
    });
  }, []);

  return (
    <>
      <Head>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-154596215-3"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'UA-154596215-3');
        `
          }}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.00, maximum-scale=1, minimum-scale=1.00"
        />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />

        {favicons()}
        {splash()}
      </Head>

      <GraphQLProvider client={client}>
        <Component {...pageProps} />
      </GraphQLProvider>
    </>
  );
};

export default MyApp;

function favicons(color = '#004691') {
  return (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/static/favicon/apple-touch-icon.png?v=Nma5vmqjAA"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/static/favicon/favicon-32x32.png?v=Nma5vmqjAA"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/static/favicon/favicon-16x16.png?v=Nma5vmqjAA"
      />
      <link rel="manifest" href="/static/manifest.json?v=2Nma5vmqjAA" />
      <link
        rel="mask-icon"
        href="/static/favicon/safari-pinned-tab.svg?v=Nma5vmqjAA"
        color="#5bbad5"
      />
      <link
        rel="shortcut icon"
        href="/static/favicon/favicon.ico?v=Nma5vmqjAA"
      />
      <meta name="apple-mobile-web-app-title" content="Tim - Folheto Digital" />
      <meta name="application-name" content="Tim - Folheto Digital" />
      <meta name="msapplication-TileColor" content="#b91d47" />
      <meta
        name="msapplication-config"
        content="/static/favicon/browserconfig.xml?v=Nma5vmqjAA"
      />
      <meta name="theme-color" content="#ffffff" />

      <meta name="msapplication-TileColor" content={color} />

      <meta name="theme-color" content={color} />
    </>
  );
}
function splash() {
  return (
    <>
      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2048-2732.png"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2732-2048.png"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1668-2388.png"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2388-1668.png"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1668-2224.png"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2224-1668.png"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1536-2048.png"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2048-1536.png"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1242-2688.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2688-1242.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />
      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1125-2436.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2436-1125.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-828-1792.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1792-828.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1242-2208.png"
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-2208-1242.png"
        media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-750-1334.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1334-750.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-640-1136.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
      />

      <link
        rel="apple-touch-startup-image"
        href="/static/splash/apple-splash-1136-640.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
      />
    </>
  );
}
